<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-09 10:59:48
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-12 04:19:15
-->
<template>
  <el-dialog v-model="drawer" :title="title" width="600px">
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="选择分组" prop="father_id" v-if="action == 0">
        <el-select v-model="form.father_id" placeholder="">
          <el-option
            v-for="item in groups"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="action == 0 ? '名称' : '分组名称'" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
        <div class="help-block">名称使用后将无法更改</div>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
      <el-form-item label="序号(正序)">
        <el-col :span="6">
          <el-input v-model="form.sequence" placeholder="请输入序号"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="可搜索" v-if="action == 0">
        <el-switch v-model="form.is_search"></el-switch>
        <div class="help-block">可以作为搜索房源参数、不宜设置过多项</div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" :loading="saving"
          >确定</el-button
        >
        <el-button @click="cancelClick">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      saving: false,
      title: "编辑",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        father_id: [
          {
            required: true,
            message: "分组必选",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "action", "groups", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.form = Object.assign({}, this.item);
        }
      },
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/facility/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
              this.saving = false;
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
  },
};
</script>